<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New State</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table
          :items="states"
          :fields="fields"
          @editFunc="editStateData"
          @delFunc="delStateData"
          @getStateLatLng="getStateLatLng"
        />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal
        v-model="myModal"
        id="modal-centered"
        centered
        size="lg"
        header-class="text-uppercase"
        scrollable
        :title="isEdit ? '' : ''"
        hide-footer
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0" v-if="!isEdit">Add New State</h5>
            <h5 class="mb-0" v-if="isEdit">Edit State</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit="handleSubmit">
          <b-form-group class="mb-3">
            <!-- Title -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder"
                    >State Name : </label
                  >
                  <b-form-input
                    v-model="postForm.name"
                    id="title"
                    placeholder="Enter State Title"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Latitude & Longitude -->
            <b-row>
              <b-col>
                <div>
                  <label for="lat" class="font-weight-bolder">Latitude :</label>
                  <b-form-input
                    v-model="postForm.lat"
                    id="lat"
                    placeholder="example - 16.768148"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="lng" class="font-weight-bolder"
                    >Longitude :</label
                  >
                  <b-form-input
                    v-model="postForm.lng"
                    id="lng"
                    placeholder="example - 96.174930"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" type="submit" class="col-6">
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button
              type="button"
              variant="primary"
              @click="updateStateData(postForm.id)"
              class="col-6"
            >
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <!--Model-->

    <!--State location Modal-->
    <div>
      <b-modal
        v-model="locaModal"
        id="modal-scrollable-state"
        centered
        size="lg"
        header-class="text-uppercase"
        hide-footer
        scrollable
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0">View State</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <div>
          <p>{{ state_address }}</p>

          <div ref="mapholderref"></div>
        </div>
      </b-modal>
    </div>
    <!-- State location Modal -->
  </div>
</template>
    
  <script>
import store from "@/store";
import Table from "../components/Table.vue";
import { ref, reactive, onMounted } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
  },
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "name", label: "name" },
      { key: "state_location", label: "State" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const locaModal = ref(false);
    const isEdit = ref(false);
    const states = ref([]);
    const modalLat = ref("");
    const modalLng = ref("");
    const state_address = ref("");
    const mapholderref = ref(null);
    const postForm = reactive({
      id: "",
      name: "",
      lat: "",
      lng: "",
    });

    onMounted(() => {
      console.log(mapholderref.value); // 可以拿到 input 元素！
    });
    //Datas Fetching
    const fetchStates = () => {
      spinner.value = true;
      store.dispatch("app-mypages/fetchDatas", "states").then((response) => {
        states.value = response.data.data;
        spinner.value = false;
      });
    };
    fetchStates();

    //Functions
    // Clear Func
    const clearData = () => {
      postForm.id = "";
      postForm.name = "";
      postForm.lat = "";
      postForm.lng = "";
    };

    function showPosition(position) {
      let showlat = modalLat.value;
      let showlng = modalLng.value;
      let latlng = new google.maps.LatLng(showlat, showlng);
      //   var mapholder = document.getElementById("mapholder");
      //   mapholder.style.height = "250px";
      //   mapholder.style.width = "100%";

      console.log(latlng)

      let myOptions = {
        center: latlng,
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        navigationControlOptions: {
          style: google.maps.NavigationControlStyle.SMALL,
        },
      };
      let map = new google.maps.Map(
        mapholderref.value,
        myOptions
      );
      let marker = new google.maps.Marker({
        position: latlng,
        map: map,
        title: "You are here!",
      });
    }
    const getStateLatLng = (data) => {
      modalLat.value = data.lat;
      modalLng.value = data.lng;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        state_address.value = "Geolocation is not supported by this browser.";
      }
    };

    //Data Posting
    const handleSubmit = () => {
      // spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "states",
          payload: postForm,
        })
        .then((response) => {
          spinner.value = false;
          clearData();
          fetchStates();
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Editing
    const editStateData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.id = item.id;
      postForm.name = item.name;
      postForm.lat = item.lat;
      postForm.lng = item.lng;
    };
    const updateStateData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "states",
          payload: postForm,
        })
        .then((response) => {
          fetchStates();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Deleting
    const delStateData = (id) => {
      spinner.value = true;
      console.log(id);
      store
        .dispatch("app-mypages/delData", { path: "states", id })
        .then((response) => {
          fetchStates();
          spinner.value = false;
        });
    };

    // Close Function
    const close = () => {
      locaModal.value = false;
      myModal.value = false;
      isEdit.value = false;
      clearData();
    };

    return {
      fields,
      state_address,
      spinner,
      myModal,
      locaModal,
      mapholderref,
      getStateLatLng,
      isEdit,
      states,
      handleSubmit,
      postForm,
      editStateData,
      updateStateData,
      delStateData,
      close,
    };
  },
};
</script>